*,
*::before,
*::after {
  box-sizing: border-box;
}

* {
  margin: 0;
}

html {
  font-size: 16px;
  line-height: 16px;
}
body {
  margin: 0;
  font-family:
    "Helvetica neue",
    -apple-system,
    BlinkMacSystemFont,
    "Segoe UI",
    "Roboto",
    "Oxygen",
    "Ubuntu",
    "Cantarell",
    "Fira Sans",
    "Droid Sans",
    "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  --red: #ca4948;
  --white: #e2e3e5;
  --lightGray: #dddddd;
  --gray: #c0c0c0;
  --darkGray: #808080;
  --black: #131414;
  --yellow: #cd8634;
  --teal: #53c6c0;
  --blue: #4a93d9;
  background-color: var(--black);
  color: var(--white);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* TYPOGRAPHY */

h1 {
  margin: 0px;
  font-weight: normal;
  font-size: 2.5rem;
  line-height: 3rem;
  margin-bottom: 16px;
  @media only screen and (min-device-width: 768px) {
    font-size: 3rem;
    line-height: 4rem;
  }
}

h2 {
  margin: 0px;
  font-size: 2rem;
  font-weight: normal;
  line-height: 2.5rem;
  margin-bottom: 16px;
  @media only screen and (min-device-width: 768px) {
    font-size: 2.25rem;
    line-height: 4.5rem;
  }
  /* @media @medium {
		font-size: 2.5rem;
	}	
	@media @large {
		font-size: 2.25rem;
	} */
}

h3 {
  margin: 0px;
  font-size: 2rem;
  line-height: 4rem;
  font-weight: bold;
  /* @media @large {
		font-size: 1.7r5em;
	} */
}

h4 {
  margin: 0px;
  font-size: 1.75rem;
  line-height: 2.5rem;
  font-weight: normal;
  /* @media @large {
		font-size: 1.5em;
	} */
}

h5 {
  margin: 0px;
  font-size: 1.5rem;
  line-height: 3rem;
  font-weight: normal;
  /* @media @large {
		font-size: 1.25em;
	} */
}

h6 {
  margin: 0px;
  font-size: 1.2rem;
  line-height: 2rem;
  font-weight: bold;
  /* @media @large {
		font-size: 1.1em;
	} */
}

a,
p {
  font-size: 1.2rem;
  line-height: 1.5em;
  /* @media @medium {
        text-align: justify;
    } */
}

a {
  color: var(--blue);
  text-decoration: none;
}

a:hover {
  border-bottom: 1px solid var(--white);
  opacity: 0.8;
}

img {
  max-width: 100%;
  height: auto;
  vertical-align: middle;
  font-style: italic;
  background-repeat: no-repeat;
  background-size: cover;
  shape-margin: 1rem;
}
